.header {
  padding: 0 4vw;;
  min-height: clamp(38px, 4vw, 60px);
  background-color: #9ed3f2;
  overflow: hidden;
}

.logo {
  margin: 8px 0 2px 0;
  width: 50%;
  max-width: 200px;
}

.main {
  display: flex;
  justify-content: space-around;
  min-height: calc(100vh - 120px);
  align-content: center;
  flex-wrap: wrap-reverse;
}

.alignCenter {
  margin: 18px 0 30px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  gap: clamp(10px, 1vw, 30px);
  min-width: 320px;
}

.line {
  background-color: #dfdfdf;
  width: 1vw;
  max-width: 5px;
  border-radius: 12px;
}

.mainHed {
  margin: 0;
  color: #1078b5;
  line-height: 1.1;
  font-family: Poppins, sans-serif;
  text-align: center;
  font-size: clamp(20px, 1.7vw, 60px);
}

.mainDes {
  margin: 0;
  text-align: center;
  font-size: clamp(16px, 1vw, 34px);
}

.sdl_img {
  width: 100%;
  max-width: 500px;
}

.sdl_hed {
  margin: 0;
  text-align: center;
  font-family: Scada, sans-serif;
  font-size: clamp(24px, 1.7vw, 60px);
}

.sdl_des {
  font-family: Scada, sans-serif;
  font-size: clamp(20px, 1vw, 30px);
  text-align: center;
}

.input{
  padding: clamp( 10px, 2vw, 14px)  ;
  width: clamp(300px, 70%, 700px);
  border: 1px solid #959494;
  border-radius: 8px; 
}

.btn{
  padding: 8px;
  width: clamp(300px, 70%, 800px);
  font-size:  clamp(10px, 2vw, 28px);
  background-color: #32B2FD;
  color: white;
  font-weight: 700;
  border: none;
  border-radius: 12px;
}

.or{
  width: clamp(40px, 10vw, 46px);
}

.cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  width: 200px;
  border: 2px solid #d1d1d1;
  border-radius: 12px;
  padding: 12px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  font-family: Scada, sans-serif;
}

.card_tl {
  font-weight: 100;
}

.bigCard {
  margin-top: clamp(20px, 1.6vh, 40px);
  padding: 20px 10px;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  background: linear-gradient(to bottom, #798af6, #ff866d, #edef85);
}

.bigCardHed {
  margin: 0;
  font-size: clamp(22px, 1.8vw, 34px);
  font-family: "Redressed", cursive;
  font-weight: 400;
  text-align: center;
}

.qtlys {
  display: flex;
  justify-content: space-around;
  gap: clamp(10px, 1.8vw, 60px);
}

.qlty {
  background-color: #3db6f2;
  border-radius: 12px;
  padding: 5px 10px;
  color: white;
  font-weight: 700;
}

.qlty_2K {
  background-color: #fe460e;
  border-radius: 12px;
  padding: 5px 14px;
  color: white;
  font-weight: 700;
}

.qlty_4K {
  background-color: #28e066;
  border-radius: 12px;
  padding: 5px 14px;
  color: white;
  font-weight: 700;
}



.sub {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.sub:hover {
  background-color: #45a049;
}

.eml{margin-right: 10px;
  padding: 10px;
  border: 1px solid grey;
  border-radius: 12px;
}

/* Modal Styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
.modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 500px;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.loader_body{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




.joinSection {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
}

.error {
  color: #dc2626;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
}

.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 16px;
}

.input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.btn {
  width: 100%;
  padding: 12px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn:hover {
  background-color: #2563eb;
}

.btn:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}











.footer{
  background-color: #656565;
  min-height: clamp(38px, 4vw, 60px);
  padding: 0 4vw;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
}